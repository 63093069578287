import * as React from 'react';
import { Link } from 'gatsby';

import Container from '../components/Container';
import Heading from '../components/Heading';
import Seo from '../components/Seo';
import Layout from '../containers/Layout';
import useTranslation from '../hooks/useTranslation';

function NotFoundPage({ pageContext: { currentPath, locale } }) {
  const { t } = useTranslation();

  return (
    <Layout background="hero">
      <Seo title={t('SEO.404.TITLE')} location={currentPath} locale={locale} />

      <Container className="py-[10vh] text-center space-y-8">
        <Heading level="h2" visualLevel="h3" weight="bold">
          {t('404.TITLE')}
        </Heading>
        <p>
          {t('404.CONTENT')}{' '}
          <Link to="/" className="underline hover:no-underline">
            esilehele
          </Link>
          .
        </p>
      </Container>
    </Layout>
  );
}

export default NotFoundPage;
